import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { BudgetItem } from './BudgetItem'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    marginBottom: 50,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 26,
  },
  header: {
    display: 'flex',
  },
  header_number: {
    width: 15,
    marginRight: 20,
  },
  header_title: {
    width: 220,
    marginRight: 20,
  },
  header_calculation_method: {
    width: 220,
    marginRight: 20,
  },
  header_own_contribution_amout: {
    width: 160,
    marginRight: 20,
  },
  header_subsidy_amount: {
    width: 160,
    marginRight: 20,
  },
  summary: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #ccc',
    paddingTop: 20,
    paddingBottom: 20,
  },
}))

export const BudgetStep = ({ budgets }) => {
  const ownContributionAmountSum = budgets.reduce(
    (sum, budget) => sum + (budget.ownContributionAmount || 0),
    0
  )

  const subsidyAmountSum = budgets.reduce(
    (sum, budget) => sum + (budget.subsidyAmount || 0),
    0
  )

  const totalSum = ownContributionAmountSum + subsidyAmountSum

  const classes = useStyles()

  return (
    <>
      <div className={classes.root}>
        <div className={classes.title}>
          {translate('T_MODULE_PROPOSALS_BUDGET_COSTS')}
        </div>
        <div>
          <div className={classes.header}>
            <div className={classes.header_number}></div>
            <div className={classes.header_title}>
              {translate('T_MODULE_PROPOSALS_BUDGET_TITLE')}
            </div>
            <div className={classes.header_calculation_method}>
              {translate('T_MODULE_PROPOSALS_BUDGET_CALCULATION_METHOD')}
            </div>
            <div className={classes.header_own_contribution_amout}>
              {translate('T_MODULE_PROPOSALS_BUDGET_OWN_CONTRIBUTION_AMOUNT')}
            </div>
            <div className={classes.header_subsidy_amount}>
              {translate('T_MODULE_PROPOSALS_BUDGET_SUBSIDY_AMOUNT')}
            </div>
          </div>
          {budgets.map((budget, index) => (
            <BudgetItem
              index={index}
              title={budget.title}
              calculationMethod={budget.calculationMethod}
              ownContributionAmount={budget.ownContributionAmount}
              subsidyAmount={budget.subsidyAmount}
              key={budget['@id']}
            />
          ))}
        </div>
      </div>
      <div className={classes.title}>
        {translate('T_MODULE_PROPOSALS_BUDGET_SUM')}: {totalSum}{' '}
        {translate('T_MODULE_PROPOSALS_BUDGET_PLN')}
      </div>
      <div className={classes.summary}>
        <div>
          <div>{translate('T_MODULE_PROPOSALS_BUDGET_TOTAL_PROJECT_COST')}</div>
          {totalSum
            ? `${Math.round((ownContributionAmountSum / totalSum) * 100)} %`
            : ''}
        </div>
        <div>
          <div>
            {translate('T_MODULE_PROPOSALS_BUDGET_OWN_CONTRIBUTION_AMOUNT')}
          </div>
          {ownContributionAmountSum}{' '}
          {translate('T_MODULE_PROPOSALS_BUDGET_PLN')}
        </div>
        <div>
          <div>{translate('T_MODULE_PROPOSALS_BUDGET_SUBSIDY_AMOUNT')}</div>
          {subsidyAmountSum} {translate('T_MODULE_PROPOSALS_BUDGET_PLN')}
        </div>
      </div>
    </>
  )
}

BudgetStep.propTypes = {
  budgets: PropTypes.arrayOf(
    PropTypes.shape({
      '@id': PropTypes.string.isRequired,
      title: PropTypes.string,
      calculationMethod: PropTypes.string,
      ownContributionAmount: PropTypes.number.isRequired,
      subsidyAmount: PropTypes.number.isRequired,
    })
  ).isRequired,
}
