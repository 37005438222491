import React from 'react'
import { Route } from 'react-router-dom'
import { Collection } from './Collection'
import { roles } from '_helpers/roles'

import { View } from './View'

const routes = () => {
  return {
    index: {
      title: 'T_MODULE_PROPOSALS_APPLICATION_STATUS_FINISHED',
      type: Route,
      render: props => <Collection {...props} />,
      path: '/proposals/finished',
      exact: true,
      resourceAccess: [roles.APPLICANT],
    },
    view: {
      type: Route,
      render: props => <View {...props} />,
      exact: true,
      resourceAccess: [roles.APPLICANT],
      path: '/proposals/finished/:id',
    },
  }
}

export default routes
